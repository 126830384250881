import React, { memo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import AppBar from '@material-ui/core/AppBar';
import IconButton from '@material-ui/core/IconButton';
import Toolbar from '@material-ui/core/Toolbar';
import Tooltip from '@material-ui/core/Tooltip';
import ExitToAppIcon from '@material-ui/icons/ExitToAppOutlined';

import { logOut } from '@tafs/acs/auth';
import logo from '@tafs/assets/img/brand/logo-light.png';
import Breadcrumbs from '../Breadcrumbs';
import TaskStatus from '../TopMenu/TaskStatus';
import Language from '../TopMenu/Language';
import AppVersion from '../TopMenu/AppVersion';
import AppSettings from '../TopMenu/AppSettings';
import Profile from '../TopMenu/Profile';
import HealthCheck from '../HealthCheck';
import styles from './index.module.css';
import { NS } from '@tafs/i18n/i18nextConfig';

const { PROD_MODE, GPB_MODE } = process.env;

const AppHeader = () => {
  const { t } = useTranslation(NS.CONSTANTS);
  const dispatch = useDispatch();
  const integrationView = useSelector(
    (state) =>
      state.user.permissions?.[state.app.workspace.permission]?.not_chosen
        ?.integration_user_audit?.view
  );

  return (
    <AppBar className={styles.appBar}>
      <Toolbar className={styles.toolbar}>
        <a href="/">
          <img src={logo} alt="TAFS engine" className={styles.logo} />
        </a>
        <Breadcrumbs />
        <div className={styles.contentRight}>
          {integrationView && <HealthCheck />}
          <AppVersion />
          <Profile />
          {!GPB_MODE && <AppSettings />}
          <TaskStatus />
          {!PROD_MODE && <Language />}
          <Tooltip title={t('Log out')} placement="bottom-end" enterDelay={300}>
            <IconButton
              className={styles.icon}
              onClick={() => dispatch(logOut())}
            >
              <ExitToAppIcon />
            </IconButton>
          </Tooltip>
        </div>
      </Toolbar>
    </AppBar>
  );
};

export default memo(AppHeader);
