import { rangeValues } from '@tafs/constants/refs/counterpartyStat';

import {
  SET_CLIENT_CONNECTION_STAT_DATA,
  RESTORE_CLIENT_INITIAL_STATE,
  SET_CLIENT_TRADING_AUDIT_DATA,
} from '../../constants/actions';

import { auditReportTypes } from '@tafs/constants/auditReport';

const dateFrom = new Date(new Date().setHours(0, 0, 0, 0));
const dateTo = new Date(new Date().setHours(0, 0, 0, 0));

const getOptions = () => {
  return {
    deals: {
      volumeAssetInDay: 0,
      volumebaseInDay: 0,
      mktVolA: 0,
      mktVolC: 0,
      partTurnoverOfClientInExchangeTurnoverInContract: 0,
      partTurnoverOfClientInExchangeTurnoverInMoney: 0,
      countPartDealsOfClientInExchangeDeals: 0,
      deviationPriceOfClientFromMarket: -1,
    },
    orders: {
      deviationVolumeAssetInDayInContract: 0,
      deviationVolumebaseInDayInContract: 0,
      deviationVolumeassetSumOrdersInContract: 0,
      deviationVolumeassetSumOrdersInMoney: 0,
      relativeCancelledOrdersInMoney: 0,
    },
  };
};

const initialState = {
  connectionStat: {
    dateFrom,
    dateTo,
    selectedRadio: rangeValues.oneWeek,
  },
  trading: {
    dateFrom,
    dateTo,
    selectedTickers: '',
    selectedClient: '',
    reportType: auditReportTypes.deals,
    options: getOptions(),
  },
};

export default function (state = initialState, action) {
  switch (action.type) {
    case SET_CLIENT_CONNECTION_STAT_DATA:
      const { dateFrom, dateTo, selectedRadio } = action.payload;
      return {
        ...state,
        connectionStat: {
          ...state.connectionStat,
          dateFrom: dateFrom || state.connectionStat.dateFrom,
          dateTo: dateTo || state.connectionStat.dateTo,
          selectedRadio: selectedRadio || state.connectionStat.selectedRadio,
        },
      };
    case RESTORE_CLIENT_INITIAL_STATE:
      return initialState;
    case SET_CLIENT_TRADING_AUDIT_DATA:
      return {
        ...state,
        trading: {
          ...state.trading,
          dateFrom: action.payload.dateFrom || state.trading.dateFrom,
          dateTo: action.payload.dateTo || state.trading.dateTo,
          selectedTickers:
            action.payload.selectedTickers !== undefined
              ? action.payload.selectedTickers
              : state.trading.selectedTickers,
          selectedClient:
            action.payload.selectedClient || state.trading.selectedClient,
          reportType: action.payload.reportType || state.trading.reportType,
        },
      };
    default:
      return state;
  }
}
