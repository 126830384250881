import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import IconButton from '@material-ui/core/IconButton';
import VerifiedUserIcon from '@material-ui/icons/VerifiedUser';
import Tooltip from '@material-ui/core/Tooltip';

import HealthCheckDialog from './HealthCheckDialog';

const HealthCheck = () => {
  const [healthCheckDialogOpen, setHealthCheckDialogOpen] = useState(false);
  const { t } = useTranslation();

  return (
    <>
      <Tooltip title={t('Health check')} enterDelay={300}>
        <IconButton
          color="inherit"
          onClick={() => setHealthCheckDialogOpen(true)}
        >
          <VerifiedUserIcon />
        </IconButton>
      </Tooltip>
      {healthCheckDialogOpen && (
        <HealthCheckDialog onClose={() => setHealthCheckDialogOpen(false)} />
      )}
    </>
  );
};

export default HealthCheck;
