import React, { useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import IconButton from '@material-ui/core/IconButton';
import Popover from '@material-ui/core/Popover';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Tooltip from '@material-ui/core/Tooltip';
import InfoIcon from '@material-ui/icons/Info';

import styles from './index.module.css';
import { NS } from '@tafs/i18n/i18nextConfig';
import {
  getWorkspacePath,
  getWorkspaceByEnum,
} from '@tafs/constants/workspaces';
import {
  INVESTIGATION_INCIDENT_LIST_PAGE,
  SCHEDULED_INCIDENT_LIST_PAGE,
  SIGNALS_LIST_PAGE_MOEX_INCIDENTS,
  SIGNALS_LIST_PAGE_MM_INCIDENTS,
  SIGNALS_LIST_PAGE_MANUAL,
  CASE_CARD,
} from '@tafs/constants/routes';
import { INVESTIGATION } from '@tafs/constants/appModes';
import DuplicateIncidentsCount from './DuplicateIncidentsCount';
import IncompleteTickers from './IncompleteTickers';
import {
  MARKET_MAKER_SIGNALS,
  MANUAL_SIGNALS,
} from '@tafs/constants/signals/signalTypes';
import StatefulLink from '@tafs/components/common/StatefulLink';
import { translateRuleOrParentName } from '@tafs/constants/reports/rules';

const INCIDENTS_LIMIT = 1000;

function StatusDetails(props) {
  const [detailsPopover, setdetailsPopover] = useState(null);
  const currentWorkspace = useSelector((state) => state.app.workspace);
  const { t } = useTranslation(NS.CONSTANTS);
  const {
    details: {
      errorMessages,
      scheduled,
      finished,
      algorithms,
      status,
      message,
      invalidTickersCurrencyContract,
      workspace,
      isSignal,
      uploadDataRowCount,
      uploadDataFailedRowCount,
      caseId,
    },
  } = props;

  const handleOpen = (event) => {
    setdetailsPopover(event.currentTarget);
  };

  const handleClose = () => {
    setdetailsPopover(null);
  };

  const optionalFields = useMemo(
    () =>
      algorithms &&
      algorithms.reduce(
        (res, algo) => {
          if (
            algo?.incidentDuplicateDtoList &&
            Object.values(algo.incidentDuplicateDtoList).some(
              (list) => list.length > 0
            )
          )
            res.areDuplicatesPresent = true;

          if (algo.reachedIncidentLimit) res.isReachedIncidentLimit = true;
          if (algo.isRunFunctionSuccess === false) res.algoFailed = true;
          if (algo.isValidTunings === false) res.tuningsInvalid = true;

          if (algo.reportDate) res.reportDate = true;

          return res;
        },
        {
          areDuplicatesPresent: false,
          isReachedIncidentLimit: false,
          algoFailed: false,
          tuningsInvalid: false,
          reportDate: false,
        }
      ),
    [algorithms]
  );

  const isCommentVisible = useMemo(
    () =>
      optionalFields?.algoFailed ||
      optionalFields?.tuningsInvalid ||
      optionalFields?.isReachedIncidentLimit ||
      invalidTickersCurrencyContract,
    [optionalFields, invalidTickersCurrencyContract]
  );

  const warningMessages = useMemo(() => {
    const res = [];

    if (optionalFields?.isReachedIncidentLimit)
      res.push(
        t(
          'One or more algorithms exceeded limit of incidents found, please change settings or contact support',
          { count: INCIDENTS_LIMIT }
        )
      );
    if (invalidTickersCurrencyContract)
      res.push(
        t(
          'One or more algorithms may not have worked correctly due to lack of reference data. Please fill in the missing currency data in Currency contract field on Issues page and rerun algorithms in Investigation mode'
        )
      );
    if (optionalFields?.tuningsInvalid)
      res.push(
        t(
          'One or more algorithms failed to run due to fine tunings validation error. Please validate fine tunings on List of rules page and rerun algorithms in Investigation mode'
        )
      );
    if (optionalFields?.algoFailed)
      res.push(
        t('One or more algorithms failed to execute. Please contact support')
      );
    if (uploadDataFailedRowCount) res.push(t('Data uploaded incompletely'));

    return res;
  }, [
    t,
    optionalFields,
    invalidTickersCurrencyContract,
    uploadDataFailedRowCount,
  ]);

  const infoMessages = useMemo(() => {
    const res = [];

    if (uploadDataRowCount && !uploadDataFailedRowCount)
      res.push(t('All data successfully uploaded'));

    return res;
  }, [t, uploadDataRowCount, uploadDataFailedRowCount]);

  return (
    <>
      <Tooltip title={t('View details')}>
        <IconButton size="small" onClick={handleOpen}>
          <InfoIcon />
        </IconButton>
      </Tooltip>
      <Popover
        anchorEl={detailsPopover}
        open={Boolean(detailsPopover)}
        onClose={handleClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        transformOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        <Table size="small" className={styles.table}>
          <TableBody>
            {errorMessages &&
              errorMessages.map((errorMessage) => (
                <TableRow className={styles.errorMessage} key={errorMessage}>
                  <TableCell className={styles.header}>
                    {t('Error message')}
                  </TableCell>
                  <TableCell>{t(errorMessage)}</TableCell>
                </TableRow>
              ))}
            {warningMessages.map((message) => (
              <TableRow key={message} className={styles.warningMessage}>
                <TableCell className={styles.header}>{t('Warning')}</TableCell>
                <TableCell>{message}</TableCell>
              </TableRow>
            ))}
            {infoMessages.map((message) => (
              <TableRow key={message}>
                <TableCell className={styles.header}>{t('Status')}</TableCell>
                <TableCell>{message}</TableCell>
              </TableRow>
            ))}
            {algorithms && (
              <TableRow>
                <TableCell className={styles.header}>
                  {t('List of rules')}
                </TableCell>
                <TableCell>
                  <Table size="small">
                    <TableHead>
                      <TableRow>
                        <TableCell className={styles.header}>
                          {t('Rule code')}
                        </TableCell>
                        <TableCell className={styles.header}>
                          {t('Rule name')}
                        </TableCell>
                        {optionalFields?.reportDate && (
                          <TableCell className={styles.header}>
                            {t('Report date')}
                          </TableCell>
                        )}
                        <TableCell className={styles.header}>
                          {isSignal ? t('Signals found') : t('Incidents found')}
                        </TableCell>
                        {optionalFields?.areDuplicatesPresent && (
                          <TableCell className={styles.header}>
                            {t('Duplicates found')}
                          </TableCell>
                        )}
                        {isCommentVisible && (
                          <TableCell className={styles.header}>
                            {t('Comment')}
                          </TableCell>
                        )}
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {algorithms
                        .sort((a, b) => a.ruleCode.localeCompare(b.ruleCode))
                        .map((algo, index) => {
                          const style =
                            algorithms.length - 1 === index
                              ? styles.noBorder
                              : null;
                          const {
                            ruleCode,
                            incidentCount,
                            reachedIncidentLimit,
                            incidentCountFilteredByLimit,
                            incidentDuplicateDtoList,
                            incidentsNew,
                            reportDate,
                            isRunFunctionSuccess,
                            isValidTunings,
                          } = algo;

                          const mode =
                            incidentsNew &&
                            Object.keys(incidentsNew).find(
                              (mode) => incidentsNew[mode].length > 0
                            );
                          const linkedWorkspace = workspace
                            ? getWorkspaceByEnum(workspace)
                            : currentWorkspace;

                          return (
                            <TableRow key={ruleCode + index}>
                              <TableCell className={style}>
                                {ruleCode}
                              </TableCell>
                              <TableCell className={style}>
                                {translateRuleOrParentName(ruleCode)}
                              </TableCell>
                              {optionalFields?.reportDate && (
                                <TableCell className={style}>
                                  {reportDate}
                                </TableCell>
                              )}
                              <TableCell className={style}>
                                {incidentCount && mode ? (
                                  !isSignal ? (
                                    <StatefulLink
                                      pathname={getWorkspacePath(
                                        linkedWorkspace,
                                        mode === INVESTIGATION
                                          ? INVESTIGATION_INCIDENT_LIST_PAGE
                                          : SCHEDULED_INCIDENT_LIST_PAGE
                                      )}
                                      storedObj={{ idList: incidentsNew[mode] }}
                                    >
                                      {incidentCount}
                                    </StatefulLink>
                                  ) : (
                                    <StatefulLink
                                      pathname={getWorkspacePath(
                                        linkedWorkspace,
                                        ruleCode === MARKET_MAKER_SIGNALS
                                          ? SIGNALS_LIST_PAGE_MM_INCIDENTS
                                          : MANUAL_SIGNALS.includes(ruleCode)
                                          ? SIGNALS_LIST_PAGE_MANUAL
                                          : SIGNALS_LIST_PAGE_MOEX_INCIDENTS
                                      )}
                                      storedObj={{ idList: incidentsNew[mode] }}
                                    >
                                      {incidentCount}
                                    </StatefulLink>
                                  )
                                ) : (
                                  0
                                )}
                              </TableCell>
                              {optionalFields?.areDuplicatesPresent && (
                                <TableCell className={style}>
                                  <DuplicateIncidentsCount
                                    isSignal={isSignal}
                                    duplicatesObj={incidentDuplicateDtoList}
                                    workspace={linkedWorkspace}
                                    ruleCode={ruleCode}
                                  />
                                </TableCell>
                              )}
                              {isCommentVisible && (
                                <TableCell className={style}>
                                  {reachedIncidentLimit && (
                                    <div>
                                      {isSignal
                                        ? t('Signals filtered count', {
                                            count: incidentCountFilteredByLimit,
                                          })
                                        : t('Incidents filtered count', {
                                            count: incidentCountFilteredByLimit,
                                          })}
                                    </div>
                                  )}
                                  {!!invalidTickersCurrencyContract?.[
                                    ruleCode
                                  ] && (
                                    <div>
                                      <IncompleteTickers
                                        tickers={
                                          invalidTickersCurrencyContract[
                                            ruleCode
                                          ]
                                        }
                                      />
                                    </div>
                                  )}
                                  {!isRunFunctionSuccess && (
                                    <div className={styles.errorComment}>
                                      {t('Algorithm run error')}
                                    </div>
                                  )}
                                  {!isValidTunings && (
                                    <div className={styles.errorComment}>
                                      {t('Fine tuning validation error')}
                                    </div>
                                  )}
                                </TableCell>
                              )}
                            </TableRow>
                          );
                        })}
                    </TableBody>
                  </Table>
                </TableCell>
              </TableRow>
            )}
            {scheduled && (
              <TableRow>
                <TableCell className={styles.header}>
                  {t('Next launch time')}
                </TableCell>
                <TableCell>{scheduled}</TableCell>
              </TableRow>
            )}
            {finished && (
              <TableRow>
                <TableCell className={styles.header}>
                  {t('Finish time')}
                </TableCell>
                <TableCell>{finished}</TableCell>
              </TableRow>
            )}
            {status ||
              (message && (
                <TableRow>
                  <TableCell className={styles.header}>{status}</TableCell>
                  <TableCell colSpan={2} className={styles.warning}>
                    {message}
                  </TableCell>
                </TableRow>
              ))}
            {uploadDataRowCount && (
              <TableRow>
                <TableCell className={styles.header}>
                  {t('Uploaded row count')}
                </TableCell>
                <TableCell>{uploadDataRowCount}</TableCell>
              </TableRow>
            )}
            {uploadDataFailedRowCount && (
              <TableRow>
                <TableCell className={styles.header}>
                  {t('Row count failed to upload')}
                </TableCell>
                <TableCell>{uploadDataFailedRowCount}</TableCell>
              </TableRow>
            )}
            {caseId && (
              <TableRow>
                <TableCell className={styles.header}>
                  {t('Case created')}
                </TableCell>
                <TableCell>
                  {
                    <Link
                      to={getWorkspacePath(
                        currentWorkspace,
                        CASE_CARD.replace(':id', caseId)
                      )}
                    >
                      {t('Open card')}
                    </Link>
                  }
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </Popover>
    </>
  );
}

StatusDetails.propTypes = {
  details: PropTypes.shape({
    errorMessages: PropTypes.arrayOf(PropTypes.string),
    algorithms: PropTypes.array,
    scheduled: PropTypes.string,
    finished: PropTypes.string,
    status: PropTypes.string,
    message: PropTypes.string,
  }),
};

export default StatusDetails;
