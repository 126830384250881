import startCase from 'lodash/startCase';
import { format } from 'date-fns';

import { formatDate } from '@tafs/utils';

export const mapFilters = (dataFilters, filterModel) => {
  const filters = { ...dataFilters };

  // we do not handle situation when filters have already in this.props.dataFilters
  // and you need to add new ones with AND/OR to them. The server cannot do this
  Object.keys(filterModel).forEach((filter) => {
    if (filterModel[filter].type) {
      filters[filter] = mapFilter(filterModel[filter]);
    }
  });

  return filters;
};

export const mapFilter = ({ type, filter: value }) => {
  let operand;

  switch (type) {
    case 'equals':
      operand = 'in:';
      break;
    case 'notEqual':
      operand = '!in:';
      break;
    default:
      operand = 'in';
      break;
  }

  return operand + value;
};

export const mapSort = (sortModel) => {
  return (
    sortModel.reduce((res, item) => {
      return (res += (item.sort === 'asc' ? '' : '!') + item.colId);
    }, '') || undefined
  );
};

export const getColumnsDefs = (data) => {
  if (!data || data.length === 0) return [];

  return Object.keys(data[0]).reduce((result, item) => {
    const headerName = startCase(item);

    result.push({
      headerName,
      field: item,
      filter: 'agTextColumnFilter',
      filterParams: {
        defaultOption: 'equals',
        filterOptions: ['equals', 'notEqual'],
      },
      enableRowGroup: true,
    });
    return result;
  }, []);
};

export const fieldTypes = {
  date: 'date',
  enum: 'enum',
  interval: 'interval',
  number: 'number',
  string: 'string',
};

const intervalToSec = (interval) => {
  const numPattern = /\d+/g;
  const vals = [...interval.matchAll(numPattern)];
  return (
    Number(vals[0][0]) * 86400 +
    Number(vals[1][0]) * 3600 +
    Number(vals[2][0]) * 60 +
    Number(vals[3][0])
  );
};

const compareWithNulls = (valueA, valueB) => {
  if (valueA === null && valueB === null) {
    return 0;
  }
  if (valueA === null) {
    return -1;
  }
  if (valueB === null) {
    return 1;
  }
  return valueA.localeCompare(valueB);
};

export const getTypedFieldConfig = (type) => {
  switch (type) {
    case fieldTypes.date:
      return {
        comparator: compareWithNulls,
        filter: 'agDateColumnFilter',
        filterParams: {
          applyButton: true,
          resetButton: true,
          defaultOption: 'equals',
          filterOptions: ['equals', 'notEqual', 'lessThan', 'greaterThan'],
          comparator: (filterLocalDateAtMidnight, cellValue) =>
            formatDate(cellValue, true).localeCompare(
              format(filterLocalDateAtMidnight, 'yyyy-MM-dd')
            ),
        },
      };
    case fieldTypes.enum:
      return { filter: true };
    case fieldTypes.interval:
      return {
        // TODO: come up with filter for interval
        comparator: (a, b) => intervalToSec(a) - intervalToSec(b),
      };
    case fieldTypes.number:
      return {
        filter: 'agNumberColumnFilter',
        filterParams: {
          applyButton: true,
          resetButton: true,
        },
      };
    case fieldTypes.string:
      return {
        filter: 'agTextColumnFilter',
        filterParams: {
          applyButton: true,
          resetButton: true,
        },
      };
    default:
      return {};
  }
};
