import React from 'react';
import Loadable from 'react-loadable';
import AccountBoxIcon from '@material-ui/icons/AccountBoxOutlined';
import AssessmentIcon from '@material-ui/icons/AssessmentOutlined';
import CardTravelIcon from '@material-ui/icons/CardTravelOutlined';
import CodeIcon from '@material-ui/icons/Code';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import EyeIcon from '@material-ui/icons/VisibilityOutlined';
import FindInPageIcon from '@material-ui/icons/FindInPage';
import FindInPageOutlinedIcon from '@material-ui/icons/FindInPageOutlined';
import GridIcon from '@material-ui/icons/AppsOutlined';
import ImportContactsIcon from '@material-ui/icons/ImportContacts';
import ListIcon from '@material-ui/icons/ListAltOutlined';
import ReceiptIcon from '@material-ui/icons/ReceiptOutlined';
import ScheduleIcon from '@material-ui/icons/ScheduleOutlined';
import SearchIcon from '@material-ui/icons/SearchOutlined';
import SecurityIcon from '@material-ui/icons/Security';
import SettingsIcon from '@material-ui/icons/SettingsApplicationsOutlined';
import ShowChartIcon from '@material-ui/icons/ShowChart';
import UploadIcon from '@material-ui/icons/CloudUploadOutlined';
import InputOutlinedIcon from '@material-ui/icons/InputOutlined';
import AssignmentOutlinedIcon from '@material-ui/icons/AssignmentOutlined';
import AssessmentOutlinedIcon from '@material-ui/icons/AssessmentOutlined';
import TrackChangesIcon from '@material-ui/icons/TrackChanges';
import GridOnIcon from '@material-ui/icons/GridOn';
import HelpOutlineOutlinedIcon from '@material-ui/icons/HelpOutlineOutlined';
import AssignmentIndIcon from '@material-ui/icons/AssignmentInd';
import EqualizerIcon from '@material-ui/icons/Equalizer';

import {
  SCHEDULED_PAGE,
  SCHEDULED_REPORTS_PAGE,
  SCHEDULED_RULES_PAGE,
  SCHEDULED_INCIDENT_LIST_PAGE,
  SCHEDULED_INCIDENT_PAGE,
  SCHEDULED_MARKET_CHART_PAGE,
  SCHEDULED_ASSOCIATED_DEALS_PAGE,
  SCHEDULED_NEIGHBORHOOD_DEALS_PAGE,
  SCHEDULED_BUSINESS_ADMIN_PANEL_PAGE,
  INVESTIGATION_REPORTS_PAGE,
  INVESTIGATION_PAGE,
  INVESTIGATION_RULES_PAGE,
  INVESTIGATION_INCIDENT_LIST_PAGE,
  INVESTIGATION_INCIDENT_PAGE,
  INVESTIGATION_RULE_CONSTRUCTOR,
  INVESTIGATION_CUSTOM_RULES,
  INVESTIGATION_MARKET_CHART_PAGE,
  INVESTIGATION_NEIGHBORHOOD_DEALS_PAGE,
  INVESTIGATION_ASSOCIATED_DEALS_PAGE,
  DEV_TOOLS_PAGE,
  UPLOAD_PAGE,
  REFERENCE_PAGE,
  REFERENCE_ISSUES_PAGE,
  REFERENCE_STAFF_PAGE,
  ORDERS_PAGE,
  ORDER_CARD_PAGE,
  MARKET_PAGE,
  MARKET_DAYS_PAGE,
  CASES_PAGE,
  DEAL_CARD_PAGE,
  CASE_CARD,
  REFERENCE_INSIDERS_PAGE,
  REFERENCE_COUNTERPARTY_PAGE,
  REFERENCE_COUNTERPARTY_CARD_PAGE,
  REFERENCE_WORKING_DAY_PAGE,
  REFERENCE_RATES_PAGE,
  REFERENCE_POSITIONS_PAGE,
  REFERENCE_TERMS_PAGE,
  NEWS_PAGE,
  NEWS_CARD_PAGE,
  DEALS_PAGE,
  ADMIN_PAGE,
  ADMIN_USER_MANAGEMENT_PAGE,
  ADMIN_APPLICATION_MONITORING,
  SECURITY_ADMIN_PAGE,
  SECURITY_ADMIN_REF_AUDIT_PAGE,
  SECURITY_ADMIN_USER_AUDIT_PAGE,
  SECURITY_ADMIN_USER_ACTION_STATS_PAGE,
  RAW_DATA_ORDERS_PAGE,
  RAW_DATA_DEALS_PAGE,
  WATCH_LISTS_PAGE,
  WATCH_LISTS_INCIDENT_LIST_PAGE,
  REFERENCE_INSIDERS_CARD_PAGE,
  WATCH_LIST_CARD_PAGE,
  REPORTS_PAGE,
  REPORTS_PAGE_ML,
  SIGNALS_PAGE,
  SIGNALS_LIST_PAGE_MOEX,
  SIGNALS_LIST_PAGE_MM,
  SIGNALS_RULES_PAGE,
  SIGNAL_PAGE_MOEX,
  SIGNAL_PAGE_MM,
  OVERLAP_INCIDENTS_PAGE,
  OVERLAP_DEALS_ORDERS_PAGE,
  OVERLAP_COUNTERPARTY_PAGE,
  INDEXES_PAGE,
  FIXING_INTEREST_PAGE,
  MOEX_FX_PAGE,
  UNPROCESSED_INCIDENTS_PAGE,
  AUDIT_CLICK_PAGE,
  SIGNALS_LIST_PAGE_MANUAL,
  SIGNAL_PAGE_MANUAL,
  REFERENCE_STAFF_CARD_PAGE,
  TAFS_REPORTS_PAGE,
  SECURITY_ADMIN_HEALTH_CHECK_PAGE,
  SECURITY_ADMIN_INTEGRATION_AUDIT_PAGE,
  SCAN_PAGE,
  MOEX_REQUESTS_PAGE,
  RISK_MODEL_PAGE,
  CROSS_DEALS_PAGE,
  CROSS_DEAL_CARD_PAGE,
  SIGNALS_LIST_PAGE_MOEX_INCIDENTS,
  SIGNALS_LIST_PAGE_MOEX_RAW,
  SIGNALS_LIST_PAGE_MM_INCIDENTS,
  SIGNALS_LIST_PAGE_MM_RAW,
  INITIAL_DATA_PAGE,
  DEALS_MOEX_PAGE,
  ORDERS_MOEX_PAGE,
  DEV_TRADES_PAGE,
  STATISTICS_MM_ORDERS_PAGE,
  PROFILE_DEVIATION_OTHERS_PAGE,
  PROFILE_DEVIATION_OWN_PAGE,
  ACTIVITY_AND_PROFILING_PAGE,
  PROFILE_DEVIATION_OTHERS_INCIDENT_CARD,
  PROFILE_DEVIATION_OWN_INCIDENT_CARD,
  ML_AlGORITHMS_PAGE,
  ML_ALGORITHMS_EXAMPLES_PAGE,
  ML_ALGORITHMS_TRIGGERS_PAGE,
} from '@tafs/constants/routes';
import LoadingProgress from '@tafs/components/common/LoadingProgress';

import {
  moduleEnum,
  permissionEnum,
  isPartOf,
} from '@tafs/constants/permissions';
import { workspaceObj, getWorkspacePath } from '@tafs/constants/workspaces';
import {
  INVESTIGATION,
  SCHEDULE,
  IRRESPECTIVE,
} from '@tafs/constants/appModes';
import withAuthorization from '@tafs/components/common/withAuthorization';

const { GPB_MODE, LITE_MODE, PROD_MODE } = process.env;

const Loading = () => <LoadingProgress />;

const NeighborhoodDeals = Loadable({
  loader: () => import('@tafs/components/pages/NeighborhoodDeals'),
  loading: Loading,
});

const AssociatedDeals = Loadable({
  loader: () => import('@tafs/components/pages/AssociatedDeals'),
  loading: Loading,
});

const CaseCard = Loadable({
  loader: () => import('@tafs/components/pages/Cases/CaseCard'),
  loading: Loading,
});

const IncidentList = Loadable({
  loader: () => import('@tafs/components/pages/IncidentList'),
  loading: Loading,
});

const SignalList = Loadable({
  loader: () => import('@tafs/components/pages/SignalList'),
  loading: Loading,
});

const SignalRawList = Loadable({
  loader: () => import('@tafs/components/pages/SignalRawList'),
  loading: Loading,
});

const CustomRules = Loadable({
  loader: () => import('@tafs/components/pages/CustomRules'),
  loading: Loading,
});

const ReportPage = Loadable({
  loader: () => import('@tafs/components/pages/Dashboard'),
  loading: Loading,
});

const AlgosList = Loadable({
  loader: () => import('@tafs/components/pages/Rules'),
  loading: Loading,
});

const Deals = Loadable({
  loader: () => import('@tafs/components/pages/Reference/Deals'),
  loading: Loading,
});

const Orders = Loadable({
  loader: () => import('@tafs/components/pages/Reference/Orders'),
  loading: Loading,
});

const RawDataDeals = Loadable({
  loader: () => import('@tafs/components/pages/Reference/Deals/RawDeals'),
  loading: Loading,
});

const RawDataOrders = Loadable({
  loader: () => import('@tafs/components/pages/Reference/Orders/RawOrders'),
  loading: Loading,
});

const OrderCard = Loadable({
  loader: () => import('@tafs/components/pages/Reference/Orders/OrderCard'),
  loading: Loading,
});

const Market = Loadable({
  loader: () => import('@tafs/components/pages/Reference/Market'),
  loading: Loading,
});

const MarketDays = Loadable({
  loader: () => import('@tafs/components/pages/Reference/MarketDays'),
  loading: Loading,
});

const UploadData = Loadable({
  loader: () => import('@tafs/components/pages/UploadData'),
  loading: Loading,
});

const DealsOnTheMOEX = Loadable({
  loader: () => import('@tafs/components/pages/InitialData/DealsOnTheMOEX'),
  loading: Loading,
});

const OrdersOnTheMOEX = Loadable({
  loader: () => import('@tafs/components/pages/InitialData/OrdersOnTheMOEX'),
  loading: Loading,
});

const DealCard = Loadable({
  loader: () => import('@tafs/components/pages/Reference/Deals/DealCard'),
  loading: Loading,
});

const ScanPage = Loadable({
  loader: () => import('@tafs/components/pages/ScanPage'),
  loading: Loading,
});

const IncidentCard = Loadable({
  loader: () => import('@tafs/components/pages/IncidentCard'),
  loading: Loading,
});

const Cases = Loadable({
  loader: () => import('@tafs/components/pages/Cases'),
  loading: Loading,
});

const Issues = Loadable({
  loader: () => import('@tafs/components/pages/Reference/Issues'),
  loading: Loading,
});

const Counterparty = Loadable({
  loader: () => import('@tafs/components/pages/Reference/Counterparty'),
  loading: Loading,
});

const ClientCard = Loadable({
  loader: () =>
    import('@tafs/components/pages/Reference/Counterparty/ClientCard'),
  loading: Loading,
});

const InsiderCard = Loadable({
  loader: () => import('@tafs/components/pages/Reference/Insiders/InsiderCard'),
  loading: Loading,
});

const Insiders = Loadable({
  loader: () => import('@tafs/components/pages/Reference/Insiders'),
  loading: Loading,
});

const Staff = Loadable({
  loader: () => import('@tafs/components/pages/Reference/Staff'),
  loading: Loading,
});

const StaffCard = Loadable({
  loader: () => import('@tafs/components/pages/Reference/Staff/StaffCard'),
  loading: Loading,
});

const WorkingDays = Loadable({
  loader: () => import('@tafs/components/pages/Reference/WorkingDay'),
  loading: Loading,
});

const Rates = Loadable({
  loader: () => import('@tafs/components/pages/Reference/Rates'),
  loading: Loading,
});

const Positions = Loadable({
  loader: () => import('@tafs/components/pages/Reference/Positions'),
  loading: Loading,
});

const TermsDefinitions = Loadable({
  loader: () => import('@tafs/components/pages/Reference/TermsDefinitions'),
  loading: Loading,
});

const BusinessAdminPanel = Loadable({
  loader: () => import('@tafs/components/pages/BusinessAdminPanel'),
  loading: Loading,
});

const UserManagement = Loadable({
  loader: () => import('@tafs/components/pages/AdminPanel/UserManagement'),
  loading: Loading,
});

const RefAudit = Loadable({
  loader: () => import('@tafs/components/pages/SecurityAdmin/Audit'),
  loading: Loading,
});

const UserAudit = Loadable({
  loader: () => import('@tafs/components/pages/SecurityAdmin/UserAudit'),
  loading: Loading,
});

const UserAction = Loadable({
  loader: () => import('@tafs/components/pages/SecurityAdmin/UserAction'),
  loading: Loading,
});

const DevTools = Loadable({
  loader: () => import('@tafs/components/pages/DevTools'),
  loading: Loading,
});

const RuleConstructor = Loadable({
  loader: () => import('@tafs/components/pages/CustomRules/RuleConstructor'),
  loading: Loading,
});

const News = Loadable({
  loader: () => import('@tafs/components/pages/Reference/News'),
  loading: Loading,
});

const Indexes = Loadable({
  loader: () => import('@tafs/components/pages/Reference/Indexes'),
  loading: Loading,
});

const FixingInterest = Loadable({
  loader: () => import('@tafs/components/pages/Reference/FixingInterest'),
  loading: Loading,
});

const MoexFx = Loadable({
  loader: () => import('@tafs/components/pages/Reference/MoexFx'),
  loading: Loading,
});

const NewsCard = Loadable({
  loader: () => import('@tafs/components/pages/Reference/News/NewsCard'),
  loading: Loading,
});

const ApplicationMonitoring = Loadable({
  loader: () => import('@tafs/components/pages/AdminPanel/Monitoring'),
  loading: Loading,
});

const MarketChart = Loadable({
  loader: () =>
    import('@tafs/components/pages/Dashboard/ChartPanel/MarketChartPage'),
  loading: Loading,
});

const WatchLists = Loadable({
  loader: () => import('@tafs/components/pages/WatchLists'),
  loading: Loading,
});

const WatchListCard = Loadable({
  loader: () => import('@tafs/components/pages/WatchLists/WatchListCard'),
  loading: Loading,
});

const MLPivotTable = Loadable({
  loader: () => import('@tafs/components/pages/Reports/MLPivotTable'),
  loading: Loading,
});

const OverlapIncidents = Loadable({
  loader: () =>
    import('@tafs/components/pages/Reports/OverlapReports/OverlapIncidents'),
  loading: Loading,
});

const OverlapDealsOrders = Loadable({
  loader: () =>
    import('@tafs/components/pages/Reports/OverlapReports/OverlapDealsOrders'),
  loading: Loading,
});

const OverlapCounterparty = Loadable({
  loader: () =>
    import('@tafs/components/pages/Reports/OverlapReports/OverlapCounterparty'),
  loading: Loading,
});

const UnprocessedIncidents = Loadable({
  loader: () =>
    import(
      '@tafs/components/pages/Reports/OverlapReports/UnprocessedIncidents'
    ),
  loading: Loading,
});

const OneClickAudit = Loadable({
  loader: () => import('@tafs/components/pages/OneClickAudit'),
  loading: Loading,
});

const HealthCheck = Loadable({
  loader: () => import('@tafs/components/pages/SecurityAdmin/HealthCheck'),
  loading: Loading,
});

const IntegrationAudit = Loadable({
  loader: () => import('@tafs/components/pages/SecurityAdmin/IntegrationAudit'),
  loading: Loading,
});

const TafsReports = Loadable({
  loader: () => import('@tafs/components/pages/TafsReports'),
  loading: Loading,
});

const MoexRequests = Loadable({
  loader: () => import('@tafs/components/pages/MoexRequests'),
  loading: Loading,
});

const RiskModel = Loadable({
  loader: () => import('@tafs/components/pages/RiskModel'),
  loading: Loading,
});

const CrossDeals = Loadable({
  loader: () => import('@tafs/components/pages/Reference/CrossDeals'),
  loading: Loading,
});

const CrossDealCard = Loadable({
  loader: () =>
    import('@tafs/components/pages/Reference/CrossDeals/CrossDealCard'),
  loading: Loading,
});

const DevTrades = Loadable({
  loader: () => import('@tafs/components/pages/Reference/DevTrades'),
  loading: Loading,
});

const MarketMakerOrdersStatistics = Loadable({
  loader: () =>
    import('@tafs/components/pages/Reference/MarketMakerOrdersStatistics'),
  loading: Loading,
});

const ProfileDeviations = Loadable({
  loader: () =>
    import('@tafs/components/pages/ActivityAndProfiling/ProfileDeviations'),
  loading: Loading,
});

const ProfileDeviationsIncCard = Loadable({
  loader: () =>
    import(
      '@tafs/components/pages/ActivityAndProfiling/ProfileDeviations/IncidentCard'
    ),
  loading: Loading,
});

const MLAlgorithms = Loadable({
  loader: () => import('@tafs/components/pages/MLAlgorithms'),
  loading: Loading,
});

const MLExamples = Loadable({
  loader: () => import('@tafs/components/pages/MLAlgorithms/MLExamples'),
  loading: Loading,
});

const MLTriggers = Loadable({
  loader: () => import('@tafs/components/pages/MLAlgorithms/MLTriggers'),
  loading: Loading,
});

const WS_ADMIN = workspaceObj.ADMIN;
const WS_SECURITY = workspaceObj.SECURITY;
const WS_ANALYST = workspaceObj.ANALYST;
const WS_COMPLIANCE = workspaceObj.COMPLIANCE;

// Admin permissions
const usermanagement_view = [
  IRRESPECTIVE.toLowerCase(),
  moduleEnum.USER_MANAGEMENT,
  permissionEnum.VIEW,
].join('.');
const perfmonitoring_view = [
  IRRESPECTIVE.toLowerCase(),
  moduleEnum.APP_PERF_MONITORING,
  permissionEnum.VIEW,
].join('.');
const systemproperty_view = [
  IRRESPECTIVE.toLowerCase(),
  moduleEnum.SYSTEM_PROPERTY,
  permissionEnum.VIEW,
].join('.');

// Security admin permissions
const refaudit_view = [
  IRRESPECTIVE.toLowerCase(),
  moduleEnum.REF_AUDIT,
  permissionEnum.VIEW,
].join('.');
const useraudit_view = [
  IRRESPECTIVE.toLowerCase(),
  moduleEnum.USER_AUDIT,
  permissionEnum.VIEW,
].join('.');
const integration_audit = [
  IRRESPECTIVE.toLowerCase(),
  moduleEnum.INTEGRATION_AUDIT,
  permissionEnum.VIEW,
].join('.');

// Schedule permissions
const schedule_dashboard_view = [
  SCHEDULE.toLowerCase(),
  moduleEnum.DASHBOARD,
  permissionEnum.VIEW,
].join('.');
const schedule_rules_view = [
  SCHEDULE.toLowerCase(),
  moduleEnum.LIST_OF_RULES,
  permissionEnum.VIEW,
].join('.');
const schedule_rules_edit = [
  SCHEDULE.toLowerCase(),
  moduleEnum.LIST_OF_RULES,
  permissionEnum.EDIT,
].join('.');
const schedule_incidents_view = [
  SCHEDULE.toLowerCase(),
  moduleEnum.INCIDENTS,
  permissionEnum.VIEW,
].join('.');
const schedule_analystpanel_view = [
  SCHEDULE.toLowerCase(),
  moduleEnum.ANALYST_PANEL,
  permissionEnum.VIEW,
].join('.');

// Investigation permissions
const investigation_dashboard_view = [
  INVESTIGATION.toLowerCase(),
  moduleEnum.DASHBOARD,
  permissionEnum.VIEW,
].join('.');
const investigation_rules_view = [
  INVESTIGATION.toLowerCase(),
  moduleEnum.LIST_OF_RULES,
  permissionEnum.VIEW,
].join('.');
const investigation_incidents_view = [
  INVESTIGATION.toLowerCase(),
  moduleEnum.INCIDENTS,
  permissionEnum.VIEW,
].join('.');
const investigation_customrules_view = [
  INVESTIGATION.toLowerCase(),
  moduleEnum.CUSTOM_RULES,
  permissionEnum.VIEW,
].join('.');

// Mode-independent permissions
const reference_view = [
  IRRESPECTIVE.toLowerCase(),
  moduleEnum.REFERENCE,
  permissionEnum.VIEW,
].join('.');
const uploaddata_view = [
  IRRESPECTIVE.toLowerCase(),
  moduleEnum.LOADER_DATA,
  permissionEnum.VIEW,
].join('.');
const watchlists_view = [
  IRRESPECTIVE.toLowerCase(),
  moduleEnum.WATCH_LISTS,
  permissionEnum.VIEW,
].join('.');
const cases_view = [
  IRRESPECTIVE.toLowerCase(),
  moduleEnum.CASE_MANAGEMENT,
  permissionEnum.VIEW,
].join('.');
const signals_view = [
  IRRESPECTIVE.toLowerCase(),
  moduleEnum.SIGNALS,
  permissionEnum.VIEW,
].join('.');
const overlap_view = [
  IRRESPECTIVE.toLowerCase(),
  moduleEnum.OVERLAP,
  permissionEnum.VIEW,
].join('.');
const one_click_audit_view = [
  IRRESPECTIVE.toLowerCase(),
  moduleEnum.ONE_CLICK_AUDIT,
  permissionEnum.VIEW,
].join('.');

const routes = [
  // ------------------------------ Admin ------------------------------
  {
    name: 'Admin panel',
    path: ADMIN_PAGE,
    icon: <SettingsIcon />,
    workspaces: [WS_ADMIN],
    allowed: [usermanagement_view, perfmonitoring_view],
    exact: true,
    children: [
      {
        name: 'User management',
        path: ADMIN_USER_MANAGEMENT_PAGE,
        icon: <AccountBoxIcon />,
        component: UserManagement,
        workspaces: [WS_ADMIN],
        allowed: [usermanagement_view],
        exact: true,
      },
      {
        name: 'Application performance monitoring',
        path: ADMIN_APPLICATION_MONITORING,
        icon: <GridIcon />,
        component: ApplicationMonitoring,
        workspaces: [WS_ADMIN],
        allowed: [perfmonitoring_view],
        exact: true,
      },
      ...(PROD_MODE
        ? []
        : [
            {
              name: 'Dev tools',
              path: DEV_TOOLS_PAGE,
              icon: <CodeIcon />,
              component: DevTools,
              workspaces: [WS_ADMIN],
              allowed: [systemproperty_view],
              exact: true,
            },
          ]),
    ],
  },

  // ------------------------------ Security admin ------------------------------
  {
    name: 'Security admin panel',
    path: SECURITY_ADMIN_PAGE,
    icon: <SecurityIcon />,
    workspaces: [WS_SECURITY],
    allowed: [refaudit_view, useraudit_view, integration_audit],
    exact: true,
    children: [
      {
        name: 'Ref audit',
        path: SECURITY_ADMIN_REF_AUDIT_PAGE,
        icon: <FindInPageIcon />,
        component: RefAudit,
        workspaces: [WS_SECURITY],
        allowed: [refaudit_view],
        exact: true,
      },
      {
        name: 'User audit',
        path: SECURITY_ADMIN_USER_AUDIT_PAGE,
        icon: <FindInPageIcon />,
        component: UserAudit,
        workspaces: [WS_SECURITY],
        allowed: [useraudit_view],
        exact: true,
      },
      ...(!PROD_MODE && GPB_MODE
        ? [
            {
              name: 'Integration audit',
              path: SECURITY_ADMIN_INTEGRATION_AUDIT_PAGE,
              icon: <FindInPageIcon />,
              component: IntegrationAudit,
              workspaces: [WS_SECURITY],
              allowed: [integration_audit],
              exact: true,
            },
            {
              name: 'Health check',
              path: SECURITY_ADMIN_HEALTH_CHECK_PAGE,
              icon: <FindInPageIcon />,
              component: HealthCheck,
              workspaces: [WS_SECURITY],
              allowed: [integration_audit],
              exact: true,
            },
          ]
        : []),
      ...(GPB_MODE
        ? []
        : [
            {
              name: 'Health check',
              path: SECURITY_ADMIN_HEALTH_CHECK_PAGE,
              icon: <FindInPageIcon />,
              component: HealthCheck,
              workspaces: [WS_SECURITY],
              allowed: [integration_audit],
              exact: true,
            },
            {
              name: 'User action statistics',
              path: SECURITY_ADMIN_USER_ACTION_STATS_PAGE,
              icon: <FindInPageIcon />,
              component: UserAction,
              workspaces: [WS_SECURITY],
              allowed: [useraudit_view],
              exact: true,
            },
          ]),
    ],
  },

  // ------------------------------ Scan page ------------------------------
  ...(PROD_MODE
    ? []
    : [
        {
          name: 'Scanning',
          path: SCAN_PAGE,
          icon: <TrackChangesIcon />,
          component: ScanPage,
          workspaces: [WS_ANALYST],
          allowed: [schedule_rules_edit],
          exact: true,
        },
      ]),

  // ------------------------------ TAFS-Lite analyst ------------------------------
  ...(LITE_MODE
    ? [
        {
          name: 'Dashboard',
          icon: <AssessmentIcon />,
          path: SCHEDULED_REPORTS_PAGE,
          component: ReportPage,
          workspaces: [WS_ANALYST],
          allowed: [schedule_dashboard_view],
          exact: true,
        },
        {
          name: 'Risk model',
          icon: <GridOnIcon />,
          path: RISK_MODEL_PAGE,
          component: RiskModel,
          workspaces: [WS_ANALYST],
          allowed: [reference_view],
          exact: true,
        },
        {
          name: 'Incidents and signals',
          icon: <ErrorOutlineIcon />,
          workspaces: [WS_ANALYST],
          allowed: [cases_view, schedule_incidents_view, signals_view],
          exact: true,
          children: [
            {
              name: 'Case management',
              icon: <CardTravelIcon />,
              path: CASES_PAGE,
              component: Cases,
              workspaces: [WS_ANALYST],
              allowed: [cases_view],
              exact: true,
            },
            {
              name: 'Incidents',
              icon: <ErrorOutlineIcon />,
              path: SCHEDULED_INCIDENT_LIST_PAGE,
              component: IncidentList,
              workspaces: [WS_ANALYST],
              allowed: [schedule_incidents_view],
              exact: true,
            },
            {
              name: 'Signals about non-standard transactions from MOEX',
              icon: <ErrorOutlineIcon />,
              path: SIGNALS_LIST_PAGE_MOEX,
              workspaces: [WS_ANALYST],
              allowed: [signals_view],
              exact: true,
              children: [
                {
                  name: 'Signal incidents',
                  icon: <ErrorOutlineIcon />,
                  path: SIGNALS_LIST_PAGE_MOEX_INCIDENTS,
                  component: SignalList,
                  workspaces: [WS_ANALYST],
                  allowed: [signals_view],
                  exact: true,
                },
                {
                  name: 'Raw signals',
                  icon: <ErrorOutlineIcon />,
                  path: SIGNALS_LIST_PAGE_MOEX_RAW,
                  component: SignalRawList,
                  workspaces: [WS_ANALYST],
                  allowed: [signals_view],
                  exact: true,
                },
              ],
            },
            {
              name: 'Signals about non-standard transactions Market maker',
              icon: <ErrorOutlineIcon />,
              path: SIGNALS_LIST_PAGE_MM,
              workspaces: [WS_ANALYST],
              allowed: [signals_view],
              exact: true,
              children: [
                {
                  name: 'Signal incidents',
                  icon: <ErrorOutlineIcon />,
                  path: SIGNALS_LIST_PAGE_MM_INCIDENTS,
                  component: SignalList,
                  workspaces: [WS_ANALYST],
                  allowed: [signals_view],
                  exact: true,
                },
                {
                  name: 'Raw signals',
                  icon: <ErrorOutlineIcon />,
                  path: SIGNALS_LIST_PAGE_MM_RAW,
                  component: SignalRawList,
                  workspaces: [WS_ANALYST],
                  allowed: [signals_view],
                  exact: true,
                },
              ],
            },
          ],
        },
        {
          name: 'Audit',
          icon: <FindInPageOutlinedIcon />,
          workspaces: [WS_ANALYST],
          allowed: [one_click_audit_view, reference_view],
          exact: true,
          children: [
            {
              name: 'One-click audit',
              icon: <FindInPageOutlinedIcon />,
              path: AUDIT_CLICK_PAGE,
              component: OneClickAudit,
              workspaces: [WS_ANALYST],
              allowed: [one_click_audit_view],
              exact: true,
            },
            {
              name: 'Query processing',
              icon: <ReceiptIcon />,
              path: MOEX_REQUESTS_PAGE,
              component: MoexRequests,
              workspaces: [WS_ANALYST],
              allowed: [reference_view],
              exact: true,
            },
          ],
        },
        {
          name: 'List of rules',
          icon: <ListIcon />,
          workspaces: [WS_ANALYST],
          allowed: [one_click_audit_view, reference_view],
          exact: true,
          children: [
            {
              name: 'TAFS rules',
              icon: <ListIcon />,
              path: SCHEDULED_RULES_PAGE,
              component: AlgosList,
              workspaces: [WS_ANALYST, WS_COMPLIANCE],
              allowed: [schedule_rules_view],
              exact: true,
            },
            {
              name: 'Custom rules',
              icon: <SettingsIcon />,
              path: INVESTIGATION_CUSTOM_RULES,
              component: CustomRules,
              workspaces: [WS_ANALYST],
              allowed: [investigation_customrules_view],
              exact: true,
            },
          ],
        },
      ]
    : []),

  // ------------------------------ Scheduled ------------------------------
  ...(LITE_MODE
    ? []
    : [
        {
          name: 'Schedule',
          path: SCHEDULED_PAGE,
          icon: <ScheduleIcon />,
          workspaces: [WS_ANALYST, WS_COMPLIANCE],
          allowed: [
            schedule_dashboard_view,
            schedule_rules_view,
            schedule_incidents_view,
            schedule_analystpanel_view,
          ],
          exact: true,
          children: [
            {
              name: 'Dashboard',
              icon: <AssessmentIcon />,
              path: SCHEDULED_REPORTS_PAGE,
              component: ReportPage,
              workspaces: [WS_ANALYST, WS_COMPLIANCE],
              allowed: [schedule_dashboard_view],
              exact: true,
            },
            {
              name: 'List of rules',
              icon: <ListIcon />,
              path: SCHEDULED_RULES_PAGE,
              component: AlgosList,
              workspaces: [WS_ANALYST, WS_COMPLIANCE],
              allowed: [schedule_rules_view],
              exact: true,
            },
            {
              name: 'Incidents',
              icon: <ErrorOutlineIcon />,
              path: SCHEDULED_INCIDENT_LIST_PAGE,
              component: IncidentList,
              workspaces: [WS_ANALYST, WS_COMPLIANCE],
              allowed: [schedule_incidents_view],
              exact: true,
            },
            {
              name: 'Analyst panel',
              icon: <SettingsIcon />,
              path: SCHEDULED_BUSINESS_ADMIN_PANEL_PAGE,
              component: BusinessAdminPanel,
              workspaces: [WS_ANALYST, WS_COMPLIANCE],
              allowed: [schedule_analystpanel_view],
              exact: true,
            },
          ],
        },
      ]),
  {
    name: 'Incident card',
    path: SCHEDULED_INCIDENT_PAGE,
    component: IncidentCard,
    workspaces: [WS_ANALYST, WS_COMPLIANCE],
    allowed: [schedule_incidents_view],
  },
  {
    name: 'Neighborhood deals',
    path: SCHEDULED_NEIGHBORHOOD_DEALS_PAGE,
    component: NeighborhoodDeals,
    workspaces: [WS_ANALYST, WS_COMPLIANCE],
    allowed: [reference_view],
  },
  {
    name: 'Associated deals',
    path: SCHEDULED_ASSOCIATED_DEALS_PAGE,
    component: AssociatedDeals,
    workspaces: [WS_ANALYST, WS_COMPLIANCE],
    allowed: [reference_view],
  },
  {
    name: 'Market chart',
    path: SCHEDULED_MARKET_CHART_PAGE,
    component: MarketChart,
    workspaces: [WS_ANALYST, WS_COMPLIANCE],
    exact: true,
    allowed: [reference_view],
  },

  // ------------------------------ Investigation ------------------------------
  ...(LITE_MODE
    ? []
    : [
        {
          name: 'Investigation',
          icon: <SearchIcon />,
          path: INVESTIGATION_PAGE,
          workspaces: [WS_ANALYST, WS_COMPLIANCE],
          allowed: [
            investigation_dashboard_view,
            investigation_rules_view,
            investigation_incidents_view,
            investigation_customrules_view,
          ],
          exact: true,
          children: [
            {
              name: 'Dashboard',
              icon: <AssessmentIcon />,
              path: INVESTIGATION_REPORTS_PAGE,
              component: ReportPage,
              workspaces: [WS_ANALYST, WS_COMPLIANCE],
              allowed: [investigation_dashboard_view],
              exact: true,
            },
            {
              name: 'List of rules',
              icon: <ListIcon />,
              path: INVESTIGATION_RULES_PAGE,
              component: AlgosList,
              workspaces: [WS_ANALYST, WS_COMPLIANCE],
              allowed: [investigation_rules_view],
              exact: true,
            },
            {
              name: 'Incidents',
              icon: <ErrorOutlineIcon />,
              path: INVESTIGATION_INCIDENT_LIST_PAGE,
              component: IncidentList,
              workspaces: [WS_ANALYST, WS_COMPLIANCE],
              allowed: [investigation_incidents_view],
              exact: true,
            },
            {
              name: 'Custom rules',
              icon: <SettingsIcon />,
              path: INVESTIGATION_CUSTOM_RULES,
              component: CustomRules,
              workspaces: [WS_ANALYST],
              allowed: [investigation_customrules_view],
              exact: true,
            },
          ],
        },
      ]),
  {
    name: 'Neighborhood deals',
    path: INVESTIGATION_NEIGHBORHOOD_DEALS_PAGE,
    component: NeighborhoodDeals,
    workspaces: [WS_ANALYST, WS_COMPLIANCE],
    allowed: [reference_view],
  },
  {
    name: 'Associated deals',
    path: INVESTIGATION_ASSOCIATED_DEALS_PAGE,
    component: AssociatedDeals,
    workspaces: [WS_ANALYST, WS_COMPLIANCE],
    allowed: [reference_view],
  },
  {
    name: 'Incident card',
    path: INVESTIGATION_INCIDENT_PAGE,
    component: IncidentCard,
    workspaces: [WS_ANALYST, WS_COMPLIANCE],
    allowed: [investigation_incidents_view],
  },
  {
    name: 'Rule constructor',
    path: INVESTIGATION_RULE_CONSTRUCTOR,
    component: RuleConstructor,
    workspaces: [WS_ANALYST, WS_COMPLIANCE],
    allowed: [investigation_customrules_view],
    exact: true,
  },
  {
    name: 'Market chart',
    path: INVESTIGATION_MARKET_CHART_PAGE,
    component: MarketChart,
    workspaces: [WS_ANALYST, WS_COMPLIANCE],
    allowed: [reference_view],
    exact: true,
  },

  // ------------------------------ Initial data ------------------------------
  ...(GPB_MODE && !PROD_MODE
    ? [
        {
          name: 'Initial data',
          icon: <ReceiptIcon />,
          path: INITIAL_DATA_PAGE,
          workspaces: [WS_ANALYST, WS_COMPLIANCE],
          allowed: [reference_view],
          exact: true,
          children: [
            {
              name: 'Deals on the MOEX',
              icon: <ReceiptIcon />,
              path: DEALS_MOEX_PAGE,
              component: DealsOnTheMOEX,
              workspaces: [WS_ANALYST, WS_COMPLIANCE],
              allowed: [reference_view],
            },
            {
              name: 'Orders on the MOEX',
              icon: <ReceiptIcon />,
              path: ORDERS_MOEX_PAGE,
              component: OrdersOnTheMOEX,
              workspaces: [WS_ANALYST, WS_COMPLIANCE],
              allowed: [reference_view],
            },
          ],
        },
      ]
    : []),

  // ------------------------------ Upload data ------------------------------
  {
    name: 'Upload data',
    icon: <UploadIcon />,
    path: UPLOAD_PAGE,
    component: UploadData,
    workspaces: [WS_ANALYST],
    allowed: [uploaddata_view],
    exact: true,
  },

  // ------------------------------ Watch lists ------------------------------
  {
    name: 'Watch lists',
    icon: <EyeIcon />,
    path: WATCH_LISTS_PAGE,
    component: WatchLists,
    workspaces: [WS_ANALYST, WS_COMPLIANCE],
    allowed: [watchlists_view],
    exact: true,
  },
  {
    name: 'Incidents',
    path: WATCH_LISTS_INCIDENT_LIST_PAGE,
    component: IncidentList,
    workspaces: [WS_ANALYST, WS_COMPLIANCE],
    allowed: [watchlists_view],
  },
  {
    name: 'Watch list card',
    path: WATCH_LIST_CARD_PAGE,
    component: WatchListCard,
    workspaces: [WS_ANALYST, WS_COMPLIANCE],
    allowed: [watchlists_view],
  },

  // ------------------------------ Cases ------------------------------
  ...(LITE_MODE
    ? []
    : [
        {
          name: 'Case management',
          icon: <CardTravelIcon />,
          path: CASES_PAGE,
          component: Cases,
          workspaces: [WS_ANALYST, WS_COMPLIANCE],
          allowed: [cases_view],
          exact: true,
        },
      ]),
  {
    name: 'Case card',
    path: CASE_CARD,
    component: CaseCard,
    workspaces: [WS_ANALYST, WS_COMPLIANCE],
    allowed: [cases_view],
  },
  // ------------------------------ Risk model ----------------------------------
  ...(LITE_MODE || GPB_MODE
    ? []
    : [
        {
          name: 'Risk model',
          icon: <GridOnIcon />,
          path: RISK_MODEL_PAGE,
          component: RiskModel,
          workspaces: [WS_ANALYST],
          allowed: [reference_view],
          exact: true,
        },
      ]),
  // ------------------------------ Moex requests ----------------------------------
  ...(LITE_MODE || GPB_MODE
    ? []
    : [
        {
          name: 'Query processing',
          icon: <ReceiptIcon />,
          path: MOEX_REQUESTS_PAGE,
          component: MoexRequests,
          workspaces: [WS_ANALYST],
          allowed: [reference_view],
          exact: true,
        },
      ]),
  // ------------------------------ Audit ----------------------------------
  ...(LITE_MODE
    ? []
    : [
        {
          name: 'One-click audit',
          icon: <FindInPageOutlinedIcon />,
          path: AUDIT_CLICK_PAGE,
          component: OneClickAudit,
          workspaces: [WS_ANALYST, WS_COMPLIANCE],
          allowed: [one_click_audit_view],
          exact: true,
        },
      ]),

  // ------------------------------ Signals --------------------------------
  ...(LITE_MODE
    ? []
    : [
        {
          name: 'External system signals',
          icon: <InputOutlinedIcon />,
          path: SIGNALS_PAGE,
          workspaces: [WS_ANALYST, WS_COMPLIANCE],
          exact: true,
          allowed: [signals_view],
          children: [
            {
              name: 'Signals about non-standard transactions from MOEX',
              icon: <ErrorOutlineIcon />,
              path: SIGNALS_LIST_PAGE_MOEX,
              workspaces: [WS_ANALYST, WS_COMPLIANCE],
              allowed: [signals_view],
              exact: true,
              children: [
                {
                  name: 'Signal incidents',
                  icon: <ErrorOutlineIcon />,
                  path: SIGNALS_LIST_PAGE_MOEX_INCIDENTS,
                  component: SignalList,
                  workspaces: [WS_ANALYST, WS_COMPLIANCE],
                  allowed: [signals_view],
                  exact: true,
                },
                {
                  name: 'Raw signals',
                  icon: <ErrorOutlineIcon />,
                  path: SIGNALS_LIST_PAGE_MOEX_RAW,
                  component: SignalRawList,
                  workspaces: [WS_ANALYST, WS_COMPLIANCE],
                  allowed: [signals_view],
                  exact: true,
                },
                {
                  name: 'Transactions with significant price deviation',
                  icon: <ReceiptIcon />,
                  path: DEV_TRADES_PAGE,
                  component: DevTrades,
                  workspaces: [WS_ANALYST, WS_COMPLIANCE],
                  allowed: [reference_view],
                  exact: true,
                },
              ],
            },
            {
              name: 'Signals about non-standard transactions Market maker',
              icon: <ErrorOutlineIcon />,
              path: SIGNALS_LIST_PAGE_MM,
              workspaces: [WS_ANALYST, WS_COMPLIANCE],
              allowed: [signals_view],
              exact: true,
              children: [
                {
                  name: 'Signal incidents',
                  icon: <ErrorOutlineIcon />,
                  path: SIGNALS_LIST_PAGE_MM_INCIDENTS,
                  component: SignalList,
                  workspaces: [WS_ANALYST, WS_COMPLIANCE],
                  allowed: [signals_view],
                  exact: true,
                },
                {
                  name: 'Raw signals',
                  icon: <ErrorOutlineIcon />,
                  path: SIGNALS_LIST_PAGE_MM_RAW,
                  component: SignalRawList,
                  workspaces: [WS_ANALYST, WS_COMPLIANCE],
                  allowed: [signals_view],
                  exact: true,
                },
              ],
            },
            {
              name: 'List of algorithms by signals',
              icon: <ListIcon />,
              path: SIGNALS_RULES_PAGE,
              component: AlgosList,
              workspaces: [WS_ANALYST, WS_COMPLIANCE],
              allowed: [signals_view],
              exact: true,
            },
          ],
        },
      ]),
  {
    name: 'Signals',
    path: SIGNALS_LIST_PAGE_MANUAL,
    component: SignalList,
    workspaces: [WS_ANALYST, WS_COMPLIANCE],
    allowed: [signals_view],
    exact: true,
  },
  {
    name: 'Signal card',
    path: SIGNAL_PAGE_MOEX,
    component: IncidentCard,
    workspaces: [WS_ANALYST, WS_COMPLIANCE],
    allowed: [signals_view],
  },
  {
    name: 'Signal card',
    path: SIGNAL_PAGE_MM,
    component: IncidentCard,
    workspaces: [WS_ANALYST, WS_COMPLIANCE],
    allowed: [signals_view],
  },
  {
    name: 'Signal card',
    path: SIGNAL_PAGE_MANUAL,
    component: IncidentCard,
    workspaces: [WS_ANALYST, WS_COMPLIANCE],
    allowed: [signals_view],
  },

  // ------------------------------ Reports ------------------------------
  ...(GPB_MODE
    ? [
        {
          name: 'Reports',
          icon: <AssessmentOutlinedIcon />,
          path: REPORTS_PAGE,
          workspaces: [WS_ANALYST],
          exact: true,
          allowed: [overlap_view],
          children: [
            {
              name: 'ML pivot table',
              icon: <AssignmentOutlinedIcon />,
              path: REPORTS_PAGE_ML,
              component: MLPivotTable,
              workspaces: [WS_ANALYST],
              allowed: [overlap_view],
              exact: true,
            },
            {
              name: 'Overlap incidents',
              icon: <AssignmentOutlinedIcon />,
              path: OVERLAP_INCIDENTS_PAGE,
              component: OverlapIncidents,
              workspaces: [WS_ANALYST],
              exact: true,
              allowed: [overlap_view],
            },
            {
              name: 'Overlap deals and orders',
              icon: <AssignmentOutlinedIcon />,
              path: OVERLAP_DEALS_ORDERS_PAGE,
              component: OverlapDealsOrders,
              workspaces: [WS_ANALYST],
              exact: true,
              allowed: [overlap_view],
            },
            {
              name: 'Overlap counterparty',
              icon: <AssignmentOutlinedIcon />,
              path: OVERLAP_COUNTERPARTY_PAGE,
              component: OverlapCounterparty,
              workspaces: [WS_ANALYST],
              exact: true,
              allowed: [overlap_view],
            },
            {
              name: 'Unprocessed compliance incidents',
              icon: <AssignmentOutlinedIcon />,
              path: UNPROCESSED_INCIDENTS_PAGE,
              component: UnprocessedIncidents,
              workspaces: [WS_ANALYST],
              exact: true,
              allowed: [overlap_view],
            },
          ],
        },
      ]
    : [
        {
          name: 'ML pivot table',
          icon: <AssignmentOutlinedIcon />,
          path: REPORTS_PAGE_ML,
          component: MLPivotTable,
          workspaces: [WS_ANALYST],
          allowed: [overlap_view],
          exact: true,
        },
      ]),

  // ------------------------------ TAFS Reports ------------------------------
  // TODO: move page to RnD-approved sub-menu
  ...(GPB_MODE
    ? []
    : [
        {
          name: 'TAFS reports',
          icon: <AssessmentOutlinedIcon />,
          path: TAFS_REPORTS_PAGE,
          component: TafsReports,
          workspaces: [WS_ANALYST],
          allowed: [cases_view],
          exact: true,
        },
      ]),

  // ------------------------------ Reference ------------------------------
  {
    name: 'Reference',
    icon: <ReceiptIcon />,
    path: REFERENCE_PAGE,
    workspaces: [WS_ANALYST, WS_COMPLIANCE],
    allowed: [reference_view],
    exact: true,
    children: [
      {
        name: 'Deals',
        icon: <ReceiptIcon />,
        path: DEALS_PAGE,
        component: Deals,
        workspaces: [WS_ANALYST, WS_COMPLIANCE],
        allowed: [reference_view],
        exact: true,
      },
      {
        name: 'Orders',
        icon: <ReceiptIcon />,
        path: ORDERS_PAGE,
        component: Orders,
        workspaces: [WS_ANALYST, WS_COMPLIANCE],
        allowed: [reference_view],
        exact: true,
      },
      {
        name: 'CrossDeals',
        icon: <ReceiptIcon />,
        path: CROSS_DEALS_PAGE,
        component: CrossDeals,
        workspaces: [WS_ANALYST, WS_COMPLIANCE],
        allowed: [reference_view],
        exact: true,
      },
      {
        name: 'Market maker orders statistics',
        icon: <ReceiptIcon />,
        path: STATISTICS_MM_ORDERS_PAGE,
        component: MarketMakerOrdersStatistics,
        workspaces: [WS_ANALYST, WS_COMPLIANCE],
        allowed: [reference_view],
        exact: true,
      },
      {
        name: 'Issues',
        icon: <CardTravelIcon />,
        path: REFERENCE_ISSUES_PAGE,
        component: Issues,
        workspaces: [WS_ANALYST, WS_COMPLIANCE],
        allowed: [reference_view],
        exact: true,
      },
      {
        name: 'Counterparty',
        icon: <CardTravelIcon />,
        path: REFERENCE_COUNTERPARTY_PAGE,
        component: Counterparty,
        workspaces: [WS_ANALYST, WS_COMPLIANCE],
        allowed: [reference_view],
        exact: true,
      },
      {
        name: 'Insiders',
        icon: <CardTravelIcon />,
        path: REFERENCE_INSIDERS_PAGE,
        component: Insiders,
        workspaces: [WS_ANALYST, WS_COMPLIANCE],
        allowed: [reference_view],
        exact: true,
      },
      {
        name: 'Staff',
        icon: <CardTravelIcon />,
        path: REFERENCE_STAFF_PAGE,
        component: Staff,
        workspaces: [WS_ANALYST, WS_COMPLIANCE],
        allowed: [reference_view],
        exact: true,
      },
      {
        name: 'Working days',
        icon: <CardTravelIcon />,
        path: REFERENCE_WORKING_DAY_PAGE,
        component: WorkingDays,
        workspaces: [WS_ANALYST, WS_COMPLIANCE],
        allowed: [reference_view],
        exact: true,
      },
      {
        name: 'Rates',
        icon: <ShowChartIcon />,
        path: REFERENCE_RATES_PAGE,
        component: Rates,
        workspaces: [WS_ANALYST, WS_COMPLIANCE],
        allowed: [reference_view],
        exact: true,
      },
      {
        name: 'Market',
        icon: <ShowChartIcon />,
        path: MARKET_PAGE,
        component: Market,
        workspaces: [WS_ANALYST, WS_COMPLIANCE],
        allowed: [reference_view],
        exact: true,
      },
      {
        name: 'Market days',
        icon: <ShowChartIcon />,
        path: MARKET_DAYS_PAGE,
        component: MarketDays,
        workspaces: [WS_ANALYST, WS_COMPLIANCE],
        allowed: [reference_view],
        exact: true,
      },
      {
        name: 'Client positions',
        icon: <ShowChartIcon />,
        path: REFERENCE_POSITIONS_PAGE,
        component: Positions,
        workspaces: [WS_ANALYST, WS_COMPLIANCE],
        allowed: [reference_view],
        exact: true,
      },
      {
        name: 'News',
        icon: <ImportContactsIcon />,
        path: NEWS_PAGE,
        component: News,
        workspaces: [WS_ANALYST, WS_COMPLIANCE],
        allowed: [reference_view],
        exact: true,
      },
      {
        name: 'Indexes',
        icon: <ImportContactsIcon />,
        path: INDEXES_PAGE,
        component: Indexes,
        workspaces: [WS_ANALYST, WS_COMPLIANCE],
        allowed: [reference_view],
        exact: true,
      },
      {
        name: 'Moex fx',
        icon: <ImportContactsIcon />,
        path: MOEX_FX_PAGE,
        component: MoexFx,
        workspaces: [WS_ANALYST, WS_COMPLIANCE],
        allowed: [reference_view],
        exact: true,
      },
      {
        name: 'Fixing interest',
        icon: <ImportContactsIcon />,
        path: FIXING_INTEREST_PAGE,
        component: FixingInterest,
        workspaces: [WS_ANALYST, WS_COMPLIANCE],
        allowed: [reference_view],
        exact: true,
      },
      ...(GPB_MODE
        ? []
        : [
            {
              name: 'Terms and definitions',
              icon: <HelpOutlineOutlinedIcon />,
              path: REFERENCE_TERMS_PAGE,
              component: TermsDefinitions,
              workspaces: [WS_ANALYST, WS_COMPLIANCE],
              allowed: [reference_view],
              exact: true,
            },
          ]),
    ],
  },
  ...(GPB_MODE
    ? []
    : [
        {
          name: 'Unusual activity and Profiling',
          icon: <AssignmentIndIcon />,
          path: ACTIVITY_AND_PROFILING_PAGE,
          workspaces: [WS_ANALYST, WS_COMPLIANCE],
          allowed: [reference_view],
          exact: true,
          children: [
            {
              name: "Client's profile deviation from the profiles of other clients",
              icon: <ListIcon />,
              path: PROFILE_DEVIATION_OTHERS_PAGE,
              component: ProfileDeviations,
              workspaces: [WS_ANALYST, WS_COMPLIANCE],
              allowed: [reference_view],
              exact: true,
            },
            {
              name: "Client's profile deviation from its usual profile",
              icon: <ListIcon />,
              path: PROFILE_DEVIATION_OWN_PAGE,
              component: ProfileDeviations,
              workspaces: [WS_ANALYST, WS_COMPLIANCE],
              allowed: [reference_view],
              exact: true,
            },
          ],
        },
      ]),
  {
    name: 'Raw deals data',
    path: RAW_DATA_DEALS_PAGE,
    component: RawDataDeals,
    workspaces: [WS_ANALYST, WS_COMPLIANCE],
    allowed: [reference_view],
  },
  {
    name: 'Raw orders data',
    path: RAW_DATA_ORDERS_PAGE,
    component: RawDataOrders,
    workspaces: [WS_ANALYST, WS_COMPLIANCE],
    allowed: [reference_view],
  },
  {
    name: 'Client card',
    path: REFERENCE_COUNTERPARTY_CARD_PAGE,
    component: ClientCard,
    workspaces: [WS_ANALYST, WS_COMPLIANCE],
    allowed: [reference_view],
  },
  {
    name: 'Insider card',
    path: REFERENCE_INSIDERS_CARD_PAGE,
    component: InsiderCard,
    workspaces: [WS_ANALYST, WS_COMPLIANCE],
    allowed: [reference_view],
  },
  {
    name: 'Staff card',
    path: REFERENCE_STAFF_CARD_PAGE,
    component: StaffCard,
    workspaces: [WS_ANALYST, WS_COMPLIANCE],
    allowed: [reference_view],
  },
  {
    name: 'News card',
    path: NEWS_CARD_PAGE,
    component: NewsCard,
    workspaces: [WS_ANALYST, WS_COMPLIANCE],
    allowed: [reference_view],
  },
  {
    name: 'Order card',
    path: ORDER_CARD_PAGE,
    component: OrderCard,
    workspaces: [WS_ANALYST, WS_COMPLIANCE],
    allowed: [reference_view],
  },
  {
    name: 'Deal card',
    path: DEAL_CARD_PAGE,
    component: DealCard,
    workspaces: [WS_ANALYST, WS_COMPLIANCE],
    allowed: [reference_view],
  },
  {
    name: 'Cross deal card',
    path: CROSS_DEAL_CARD_PAGE,
    component: CrossDealCard,
    workspaces: [WS_ANALYST, WS_COMPLIANCE],
    allowed: [reference_view],
  },
  {
    name: 'Incident card',
    path: PROFILE_DEVIATION_OTHERS_INCIDENT_CARD,
    component: ProfileDeviationsIncCard,
    workspaces: [WS_ANALYST, WS_COMPLIANCE],
    allowed: [reference_view],
  },
  {
    name: 'Incident card',
    path: PROFILE_DEVIATION_OWN_INCIDENT_CARD,
    component: ProfileDeviationsIncCard,
    workspaces: [WS_ANALYST, WS_COMPLIANCE],
    allowed: [reference_view],
  },

  // ------------------------------ ML-algorithms ------------------------------
  {
    name: 'ML-algorithms',
    icon: <EqualizerIcon />,
    path: ML_AlGORITHMS_PAGE,
    component: MLAlgorithms,
    workspaces: [WS_ANALYST, WS_COMPLIANCE],
    allowed: [reference_view],
    exact: true,
  },
  {
    name: 'Examples',
    path: ML_ALGORITHMS_EXAMPLES_PAGE,
    component: MLExamples,
    workspaces: [WS_ANALYST, WS_COMPLIANCE],
    allowed: [reference_view],
  },
  {
    name: 'Triggers',
    path: ML_ALGORITHMS_TRIGGERS_PAGE,
    component: MLTriggers,
    workspaces: [WS_ANALYST, WS_COMPLIANCE],
    allowed: [reference_view],
  },
];

export default routes;

export const sideMenuConfig = LITE_MODE
  ? routes.reduce((acc, cur) => {
      cur.workspaces.forEach(
        (workspace) =>
          !!cur?.icon &&
          acc.push({
            ...cur,
            ...(cur?.children && {
              children: cur.children.map((item) => ({
                ...item,
                ...(item?.children && {
                  children: item.children.map((child) => ({
                    ...child,
                    workspace,
                  })),
                }),
                workspace,
              })),
            }),
            workspace,
          })
      );
      return acc;
    }, [])
  : routes.filter((item) => !!item?.icon);

const getAllowedChildRoute = (item, workspace) => (permissions) => {
  const allowedChild = item.children.find((childItem) =>
    childItem.allowed.some((childItemPermission) =>
      isPartOf(`${workspace.permission}.${childItemPermission}`, permissions)
    )
  );

  return getWorkspacePath(workspace, allowedChild?.path || '');
};

const processRoutes = (route, acc) => {
  route.workspaces.forEach((workspace) => {
    acc.push({
      name: route.name,
      path: getWorkspacePath(workspace, route.path),
      component:
        route.component &&
        withAuthorization(route.component, workspace, route.allowed),
      exact: route.exact,
      ...(route.children && {
        getRedirectRoute: getAllowedChildRoute(route, workspace),
      }),
    });
  });

  if (route.children) {
    route.children.forEach((childRoute) => processRoutes(childRoute, acc));
  }
};

export const routeConfig = routes.reduce((acc, curr) => {
  processRoutes(curr, acc);
  return acc;
}, []);
