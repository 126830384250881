import React, { Component } from 'react';
import { connect } from 'react-redux';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-balham.css';
import 'ag-grid-enterprise';
import { AgGridReact } from 'ag-grid-react';
import { PropTypes } from 'prop-types';
import classNames from 'classnames';
import isArray from 'lodash/isArray';
import isEqual from 'lodash/isEqual';
import isEmpty from 'lodash/isEmpty';
import IconButton from '@material-ui/core/IconButton';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeftOutlined';
import ChevronRightIcon from '@material-ui/icons/ChevronRightOutlined';
import SkipNextIcon from '@material-ui/icons/SkipNextOutlined';
import SkipPreviousIcon from '@material-ui/icons/SkipPreviousOutlined';

import { apiGet } from '@tafs/services/api';
import {
  getColumnsDefs,
  mapFilters,
  mapSort,
  getTypedFieldConfig,
} from './utils';
import { autoSizeColumnsUpToMaxWidth } from '@tafs/utils';
import LoadingProgress from '@tafs/components/common/LoadingProgress';
import styles from './index.module.css';
import NoData from '../Status/NoData';
import { NS } from '@tafs/i18n/i18nextConfig';
import { withTranslation } from 'react-i18next';
import { isNaN, isObject } from 'lodash';
import CustomInstrumentsToolPanel from '../AgGridTools/CustomInstrumentsToolPanel';
import TruncatableCell, {
  MAX_CELL_WIDTH,
} from '@tafs/components/common/AgGridTools/TruncatableCell';

class DataTable extends Component {
  static defaultProps = {
    paginationPageSize: 20,
    sortable: true,
  };

  state = {
    currentPage: 1,
    totalPages: null,
    rowCount: 0,
    pageSize: 0,
  };

  gridOptions = {
    localeTextFunc: (key) => this.props.t(key, { ns: NS.AG_GRID }),
    onVirtualColumnsChanged: this.resizeColWidth,
    onFirstDataRendered: (params) => {
      if (!this.props?.ignoreAutoSizeColumns) {
        autoSizeColumnsUpToMaxWidth(params.columnApi);
      }
    },
    frameworkComponents: {
      customLoadingOverlay: LoadingProgress,
      customInstrumentsToolPanel: (props) =>
        CustomInstrumentsToolPanel({
          ...props,
          stateKey: this.props.stateKey,
        }),
      ...this.props.frameworkComponents,
    },
    defaultColDef: {
      resizable: true,
    },
    loadingOverlayComponent: 'customLoadingOverlay',
    reactNext: true,
    ensureDomOrder: true,
    ...(this.props.stateKey && {
      sideBar: {
        toolPanels: [
          {
            id: 'columns',
            labelDefault: 'Columns',
            labelKey: 'columns',
            iconKey: 'columns',
            toolPanel: 'agColumnsToolPanel',
            toolPanelParams: {
              suppressRowGroups: true,
              suppressValues: true,
              suppressPivots: true,
              suppressPivotMode: true,
              suppressSideButtons: true,
              suppressColumnFilter: true,
            },
          },
          ...(this.props.filterToolPanel
            ? [
                {
                  id: 'filters',
                  labelDefault: 'Filters',
                  labelKey: 'filters',
                  iconKey: 'filter',
                  toolPanel: 'agFiltersToolPanel',
                },
              ]
            : []),
          {
            id: 'customInstruments',
            labelKey: 'customInstruments',
            iconKey: 'smallLeft',
            toolPanel: 'customInstrumentsToolPanel',
          },
        ],
      },
    }),
    allowDragFromColumnsToolPanel: true,
    enableRangeSelection: true,
    suppressMultiRangeSelection: true,
    suppressRowClickSelection: true,
  };

  setColumnDefs = (data) => {
    const { additionalColumns } = this.props;

    if (isEmpty(this.gridColumnApi.getAllColumns())) {
      this.gridApi.setColumnDefs(
        additionalColumns
          ? additionalColumns.concat(getColumnsDefs(data))
          : getColumnsDefs(data)
      );
      this.restoreColState();
    }
  };

  getUpdatedColumnDefs = (columnDefs) => {
    const { t } = this.props;

    return columnDefs.map((col) => ({
      ...col,
      headerName: isArray(col.headerName)
        ? t(...col.headerName)
        : t(col.headerName),
      ...(col?.fieldType && getTypedFieldConfig(col.fieldType)),
      ...(col?.children && {
        children: this.getUpdatedColumnDefs(col.children),
      }),
      ...(col?.truncatable && {
        cellRendererFramework: TruncatableCell,
        maxWidth: MAX_CELL_WIDTH,
      }),
    }));
  };

  getRows = ({
    // The first row index to get.
    startRow,
    // The first row index to NOT get.
    endRow,
    // If doing Server-side sorting, contains the sort model
    sortModel,
    // If doing Server-side filtering, contains the filter model
    filterModel,
    // The grid context object
    context,
    // Callback to call when the request is successful.
    successCallback, //(rowsThisBlock: any[], lastRow?: number): void;
    // Callback to call when the request fails.
    failCallback,
  }) => {
    const { dataUrl, dataFilters, pagination, autoGenerateColumns } =
      this.props;

    this.gridApi.showLoadingOverlay();

    apiGet(dataUrl, {
      params: {
        page: pagination ? startRow / (endRow - startRow) : undefined,
        size: pagination ? endRow - startRow : undefined,
        ...mapFilters(dataFilters, filterModel),
        sort: mapSort(sortModel),
      },
    })
      .then(({ data: result }) => {
        const data = pagination ? result.data : result;
        const total = pagination ? result.total : result ? result.length : 0;

        if (autoGenerateColumns) this.setColumnDefs(data);

        if (!data || total === 0) this.gridApi.showNoRowsOverlay();
        else this.gridApi.hideOverlay();

        return successCallback(data || [], total);
      })
      .catch((err) => {
        failCallback();
      })
      .finally(this.resizeColWidth);
  };

  onGridReady = (grid) => {
    this.props.onGridReady && this.props.onGridReady(grid);
    const { api, columnApi } = grid;
    const { dataUrl } = this.props;

    this.gridApi = api;
    this.gridColumnApi = columnApi;

    if (dataUrl) {
      api.setDatasource({
        getRows: this.getRows,
      });
    }
    this.restoreColState();
    api.expandAll();
    this.resizeColWidth(grid);
  };

  restoreColState = () => {
    const { stateKey, username } = this.props;

    if (stateKey) {
      try {
        const gridStateString = localStorage.getItem(`${username}_${stateKey}`);
        if (gridStateString) {
          const { colState, sortModel, filterModel } =
            JSON.parse(gridStateString);
          colState && this.gridColumnApi.setColumnState(colState);
          sortModel && this.gridApi.setSortModel(sortModel);
          filterModel && this.gridApi.setFilterModel(filterModel);
        }
      } catch {}
    }
  };

  resizeColWidth = () => {
    if (this.gridColumnApi) this.gridColumnApi.autoSizeColumns();
  };

  onPaginationChanged = () => {
    if (this.gridApi) {
      this.setState({
        currentPage: this.gridApi.paginationGetCurrentPage() + 1,
        totalPages: this.gridApi.paginationGetTotalPages(),
        rowCount: this.gridApi.paginationGetRowCount(),
        pageSize: this.gridApi.paginationGetPageSize(),
      });
    }
  };

  paginationGoToFirstPage = () => {
    this.gridApi.paginationGoToFirstPage();
  };

  paginationGoToLastPage = () => {
    this.gridApi.paginationGoToLastPage();
  };

  paginationGoToNextPage = () => {
    this.gridApi.paginationGoToNextPage();
  };

  paginationGoToPreviousPage = () => {
    this.gridApi.paginationGoToPreviousPage();
  };

  paginationGoToPage = (e) => {
    this.gridApi.paginationGoToPage(e.target.value - 1);
  };

  selectPageSize = (e) => {
    this.gridApi.paginationSetPageSize(e.target.value);
  };

  componentDidUpdate(prevProps) {
    const { dataUrl, dataFilters, autoGenerateColumns, rowData } = this.props;

    if (dataUrl && !isEqual(prevProps.dataFilters, dataFilters))
      this.gridApi.setDatasource({
        getRows: this.getRows,
      });
    if (autoGenerateColumns && !dataUrl && rowData) {
      if (!isEqual(prevProps.rowData, rowData)) {
        this.setColumnDefs(rowData);
      }
    }

    this.resizeColWidth();
  }

  componentDidMount() {
    window.addEventListener('beforeunload', this.componentCleanUp);
  }

  componentWillUnmount() {
    this.componentCleanUp();
    window.removeEventListener('beforeunload', this.componentCleanUp);
  }

  componentCleanUp = () => {
    const { stateKey, username } = this.props;

    if (stateKey && this.gridColumnApi && this.gridApi) {
      const colState = this.gridColumnApi.getColumnState();
      const sortModel = this.gridApi.getSortModel();
      const filterModel = this.gridApi.getFilterModel();
      if (!isEmpty(colState)) {
        localStorage.setItem(
          `${username}_${stateKey}`,
          JSON.stringify({ colState, sortModel, filterModel })
        );
      }
    }
  };

  getContextMenu = (params) => {
    const { getContextMenuItems, t } = this.props;

    const copyCell = {
      icon: '<span class="ag-icon ag-icon-copy" unselectable="on"></span>',
      name: t('copyCell', { ns: NS.AG_GRID }),
      action: () => {
        try {
          const { gridApi } = this;
          const { rowIndex, column } = gridApi.getFocusedCell();
          const rowNode = gridApi.getDisplayedRowAtIndex(rowIndex);

          let value = gridApi.getValue(column.colId, rowNode);
          if (isNaN(value) || value === undefined) {
            value = rowIndex;
          } else if (column.colDef.valueFormatter) {
            value = column.colDef.valueFormatter({ value: value });
          } else if (column.colDef.cellRendererFramework) {
            const renderValue = column.colDef.cellRendererFramework({
              value: value,
              data: 'mock',
            });
            if (!isObject(renderValue) & renderValue) {
              value = renderValue;
            }
          }

          navigator.clipboard.writeText(value);
        } catch (error) {
          console.error(error);
        }
      },
    };

    let res = [
      ...(getContextMenuItems
        ? [...getContextMenuItems(params), 'separator']
        : []),
      'copy',
      'copyWithHeaders',
      'export',
    ];

    let placeIndex = res.findIndex((item) => item === 'copy');
    if (placeIndex === -1) res.unshift(copyCell);
    else res.splice(placeIndex, 0, copyCell);

    return res;
  };

  getMainMenuItems = ({ api, column, defaultItems }) => {
    const { t } = this.props;

    const menuItem = {
      name: t('Copy column', { ns: NS.AG_GRID }),
      action: function () {
        api.clearRangeSelection();
        api.addCellRange({
          rowStartIndex: api.paginationProxy.topRowBounds.rowIndex,
          rowEndIndex:
            api.paginationProxy.topRowBounds.rowIndex +
            api.paginationProxy.pageSize -
            1,
          columnStart: column.colId,
          columnEnd: column.colId,
        });
        api.copySelectedRangeToClipboard();
        api.clearRangeSelection();
      },
    };
    defaultItems.splice(4, 0, menuItem);
    return defaultItems;
  };

  render() {
    const {
      style,
      className,
      rowData,
      sortable,
      frameworkComponents,
      pagination,
      rowSelection,
      columnDefs,
      ...restProps
    } = this.props;
    const { currentPage, totalPages, rowCount, pageSize } = this.state;
    const rowModelType = rowData ? 'clientSide' : 'infinite';
    return (
      <div className={classNames('ag-theme-balham', className)} style={style}>
        <AgGridReact
          groupDefaultExpanded={10}
          overlayNoRowsTemplate={NoData.stringify()}
          gridOptions={this.gridOptions}
          rowSelection={rowSelection || 'multiple'}
          rowModelType={rowModelType}
          rowData={rowData}
          animateRows
          filter
          enableSorting={sortable}
          pagination={pagination}
          getContextMenuItems={this.getContextMenu}
          suppressPaginationPanel={true}
          onPaginationChanged={this.onPaginationChanged}
          onVirtualColumnsChanged={this.resizeColWidth}
          onRowDataChanged={this.resizeColWidth}
          columnDefs={this.getUpdatedColumnDefs(columnDefs)}
          {...restProps}
          onGridReady={this.onGridReady}
          getMainMenuItems={this.getMainMenuItems}
          onColumnResized={this.props.onColumnResized}
        />
        {pagination ? (
          <div className={styles.pagination}>
            <FormControl className={styles.input} margin="dense">
              <Select value={pageSize} onChange={this.selectPageSize}>
                <MenuItem value={20}>20</MenuItem>
                <MenuItem value={100}>100</MenuItem>
                <MenuItem value={1000}>1000</MenuItem>
              </Select>
              <FormHelperText>Page size</FormHelperText>
            </FormControl>
            <div className={styles.right}>
              <div>
                {(currentPage - 1) * pageSize + 1} to{' '}
                {Math.min(currentPage * pageSize, rowCount)} of {rowCount}{' '}
                records
              </div>
              <IconButton
                color="inherit"
                title="Go to first page"
                onClick={this.paginationGoToFirstPage}
              >
                <SkipPreviousIcon />
              </IconButton>
              <IconButton
                color="inherit"
                title="Go to previous page"
                onClick={this.paginationGoToPreviousPage}
              >
                <ChevronLeftIcon />
              </IconButton>
              <FormControl className={styles.input} margin="dense">
                <Select value={currentPage} onChange={this.paginationGoToPage}>
                  {[...Array(totalPages).keys()].map((i) => (
                    <MenuItem key={i} value={i + 1}>
                      {i + 1}
                    </MenuItem>
                  ))}
                </Select>
                <FormHelperText>Page</FormHelperText>
              </FormControl>
              <IconButton
                color="inherit"
                title="Go to next page"
                onClick={this.paginationGoToNextPage}
              >
                <ChevronRightIcon />
              </IconButton>
              <IconButton
                color="inherit"
                title="Go to last page"
                onClick={this.paginationGoToLastPage}
              >
                <SkipNextIcon />
              </IconButton>
            </div>
          </div>
        ) : null}
      </div>
    );
  }
}

DataTable.propTypes = {
  dataUrl: PropTypes.string,
  autoGenerateColumns: PropTypes.bool,
  className: PropTypes.string,
};

const mapStateToProps = function (state) {
  return {
    username: state.user.username,
  };
};

export default withTranslation([NS.CONSTANTS, NS.AG_GRID])(
  connect(mapStateToProps)(DataTable)
);
