import {
  INITIALIZE_CLIENT_AFFILIATION_FILTERS,
  SET_CLIENT_AFFILIATION_FILTERS,
} from '@tafs/constants/actions';
import { filters } from '@tafs/constants/affiliation';

const initialState = {
  affiliationFilters: Object.values(filters).reduce((res, filter) => {
    res[filter.id] = filter.default;
    return res;
  }, {}),
};

export default function (state = initialState, action) {
  switch (action.type) {
    case INITIALIZE_CLIENT_AFFILIATION_FILTERS:
      const { affiliationFilters } = initialState;
      return {
        ...state,
        affiliationFilters,
      };
    case SET_CLIENT_AFFILIATION_FILTERS:
      return {
        ...state,
        affiliationFilters: action.payload,
      };
    default:
      return state;
  }
}
