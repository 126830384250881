import React from 'react';
import { useTranslation } from 'react-i18next';
import Popover from '@material-ui/core/Popover';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';

import { getWorkspacePath } from '@tafs/constants/workspaces';
import {
  SCHEDULED_INCIDENT_LIST_PAGE,
  INVESTIGATION_INCIDENT_LIST_PAGE,
} from '@tafs/constants/routes';
import StatefulLink from '@tafs/components/common/StatefulLink';

const IncidentsPopover = ({ incidentsData, onClose, forceNewTab }) => {
  const { t } = useTranslation();

  const createIncidentLink = (path, idList, ws) =>
    idList && idList.length > 0 ? (
      <StatefulLink
        pathname={getWorkspacePath(ws, path)}
        storedObj={{ idList }}
        forceNewTab={forceNewTab}
      >
        {idList?.length}
        {forceNewTab && <OpenInNewIcon fontSize="small" />}
      </StatefulLink>
    ) : (
      0
    );

  return (
    <Popover
      open={!!incidentsData?.popoverOpened}
      anchorEl={incidentsData?.anchor}
      onClose={onClose}
      anchorReference={incidentsData?.anchorPosition && 'anchorPosition'}
      anchorPosition={incidentsData?.anchorPosition}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'center',
      }}
    >
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell>{t('Amount of incidents (Schedule)')}</TableCell>
            <TableCell>{t('Amount of incidents (Investigation)')}</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {incidentsData && (
            <TableRow>
              <TableCell>
                {createIncidentLink(
                  SCHEDULED_INCIDENT_LIST_PAGE,
                  incidentsData?.data?.SCHEDULE,
                  incidentsData.ws
                )}
              </TableCell>
              <TableCell>
                {createIncidentLink(
                  INVESTIGATION_INCIDENT_LIST_PAGE,
                  incidentsData?.data?.INVESTIGATION,
                  incidentsData.ws
                )}
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
    </Popover>
  );
};

export default IncidentsPopover;
