export const EDGES_LIMIT = 1000;

export const relationLevels = ['first', 'second', 'third'];

export const levelsEnum = {
  low: { id: 'low', min: 0, max: 0.33 },
  medium: { id: 'medium', min: 0.33, max: 0.66 },
  high: { id: 'high', min: 0.66, max: 1 },
};

export const filterTypes = { bool: 'BOOL', arr: 'ARR' };

export const filters = {
  affiliatedGeneralInfo: {
    id: 'affiliatedGeneralInfo',
    name: 'Affiliation by general information',
    type: filterTypes.bool,
    default: true,
  },
  affiliatedByConnections: {
    id: 'affiliatedByConnections',
    name: 'Affiliation by connections',
    type: filterTypes.bool,
    default: true,
  },
  affiliatedByTrueIncidents: {
    id: 'affiliatedByTrueIncidents',
    name: 'Affiliation for true incidents',
    type: filterTypes.bool,
    default: true,
  },
  affiliatedByCommonDeals: {
    id: 'affiliatedByCommonDeals',
    name: 'Affiliation for counter transactions',
    type: filterTypes.bool,
    default: true,
  },
  affiliatedCustom: {
    id: 'affiliatedCustom',
    name: 'Custom list',
    type: filterTypes.bool,
    default: true,
  },
  affiliationLevel: {
    id: 'affiliationLevel',
    name: 'Affiliation level',
    type: filterTypes.arr,
    default: Object.keys(levelsEnum),
  },
  affiliatedClientRiskCoefficient: {
    id: 'affiliatedClientRiskCoefficient',
    name: 'Risk rating',
    type: filterTypes.arr,
    default: Object.keys(levelsEnum),
  },
};

export const checkFilters = (relation, currentFilters) =>
  Object.keys(currentFilters).every((filterId) =>
    filters[filterId].type === filterTypes.bool
      ? !currentFilters[filterId] || !!relation[filterId]
      : currentFilters[filterId].some(
          (level) =>
            levelsEnum[level].min < relation[filterId] &&
            relation[filterId] <= levelsEnum[level].max
        )
  );

export const orderLevels = (levels) =>
  Object.keys(levelsEnum).filter((level) => levels.includes(level));
