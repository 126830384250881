import {
  LOCATION_CHANGE,
  SET_SIDE_MENU_CONFIG,
  SET_APP_MODE_AND_WORKSPACE,
  SET_INCIDENT_MANAGEMENT_DATA,
  SET_INCIDENT_NEWS_DATA,
  SET_INCIDENT_CHART_DATA,
  RESTORE_INCIDENT_INITIAL_STATE,
  SET_CLIENT_CONNECTION_STAT_DATA,
  RESTORE_CLIENT_INITIAL_STATE,
  RESTORE_CASE_INITIAL_STATE,
  SET_CASE_CHART_DATA,
} from '@tafs/constants/actions';
import { createStateSyncMiddleware } from 'redux-state-sync';

const stateSyncMiddlware = () =>
  createStateSyncMiddleware({
    blacklist: [
      LOCATION_CHANGE,
      SET_SIDE_MENU_CONFIG,
      SET_APP_MODE_AND_WORKSPACE,
      SET_INCIDENT_MANAGEMENT_DATA,
      SET_INCIDENT_NEWS_DATA,
      SET_INCIDENT_CHART_DATA,
      RESTORE_INCIDENT_INITIAL_STATE,
      SET_CLIENT_CONNECTION_STAT_DATA,
      RESTORE_CLIENT_INITIAL_STATE,
      RESTORE_CASE_INITIAL_STATE,
      SET_CASE_CHART_DATA,
    ],
  });

export default stateSyncMiddlware;
